@import url(https://mcansh.github.io/yeezy-logo-font/assets/css/font.css);
.App {
  text-align: center;
}
h1, h2, h3, h4, h5 {
  font-family: "Yeezy";
  font-weight: 900 !important;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.heading {
  color: black;
}

.subTitle {
  color: #868e96;
}
