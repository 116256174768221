.web-projects {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 1rem;

}

.company-logo-container>img {
  width: 150px;
  height: 95px;
  object-fit: contain;
}




.company-logo-container {
  width: 100%;
  margin: auto;

}

.web-projects>* {
  flex: 1;
  margin-bottom: 30px;
}

.starup-project-image>img {
  max-width: 100%;
  height: auto;
}

.project-card-head {
  color: rgb(36, 41, 46);
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin: 0px;
}

.startup-project-text {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-content: space-between;
}

.project-card-description {
  color: rgb(88, 96, 105);
}

.company-worked-with {
  align-items: center;
  cursor: pointer;
}

@media (max-width: 768px) {
  .starup-project-image {
    display: none;
  }


  

  .company-logo {
    max-width: 300px;
  }

  .project-subtitle {
    font-size: 16px;
    text-align: center;
  }
}