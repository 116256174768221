@import url(https://mcansh.github.io/yeezy-logo-font/assets/css/font.css);
@font-face {
  font-family: 'Agustina Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Agustina Regular'), url(/static/media/Agustina.21f233e1.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(/static/media/Montserrat-Regular.ee653992.ttf) format('woff');
  font-display: swap;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font: 19px / 23px Montserrat, "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Media Query */
@media (max-width: 1380px) {
  html,
  body {
    font-size: 16px;
    line-height: normal;
  }
}

.App {
  text-align: center;
}
h1, h2, h3, h4, h5 {
  font-family: "Yeezy";
  font-weight: 900 !important;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.heading {
  color: black;
}

.subTitle {
  color: #868e96;
}

/* header */
.header {
  background-color: #fff;
  max-width: 100%;
  padding: 15px 20px;
  margin: 0px auto;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}
.grey-color {
  color: black;
}

.header li a {
  display: block;
  padding: 20px 20px;
  color: black;
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: #f4f4f4;
}

.header .logo {
  display: block;
  flex: none;
  float: left;
  font-size: 1.5em;
  text-decoration: none;
  margin-top: 10px;
  line-height: normal;
}
.header .logo-name {
  font-family: "Yeezy";
  color: #333;
  font-weight: bold;
  font-feature-settings: "liga", "clig off";
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  padding: 0 10px;
}

/* menu */

.header .menu {
  font-family: "Yeezy";
  font-weight: 900;
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 370px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .menu {
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}
@media (max-width: 960px) and (min-width: 768px) {
  .header {
    font-size: 12px;
  }
}

.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}
.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}

.gretting-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
}

.greeting-main {
  display: flex;
}

.greeting-main > * {
  flex: 1 1;
  margin-bottom: 30px;
}
.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

.greeting-text {
  font-size: 70px;
  line-height: 1.1;
}

.greeting-text-p {
  font-size: 30px;
  line-height: 40px;
}

.greeting-image-div > img {
  max-width: 100%;
  height: auto;
}
.wave-emoji {
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  .greeting-main {
    display: block;
  }
}

.social-media-div {
  font-size: 2em;
}

.icon-button {
  margin-bottom: 10px;
}

.icon-button i {
  color: white;
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  width: 2.6rem;
  margin-bottom: 10px;
  transition: 0.2s ease-in;
}

.facebook i {
  background-color: #3b5998;
}

.linkedin i {
  background-color: #0e76a8;
}

.github i {
  background-color: #333;
}

.gitlab i {
  background-color: #fca326;
}

.google i {
  background-color: #ea4335;
}

.twitter i {
  background-color: #1da1f2;
}

.medium i {
  background-color: #000;
}

.stack-overflow i {
  background-color: #f48024;
}

.instagram i {
  background-color: #c13584;
}

/* Hover Transition */
.twitter i:hover,
.google i:hover,
.gitlab i:hover,
.github i:hover,
.linkedin i:hover,
.facebook i:hover,
.instagram i:hover,
.stack-overflow i:hover,
.medium i:hover{
  background-color: black;
}

/* Media Query */
@media (max-width: 768px) {
  .social-media-div {
    text-align: center;
  }
}

.main-button {
  background-color: #000;
  border: solid 1px #000;
  color: white;
  font-weight: 700;
  width: -webkit-max-content;
  width: max-content;
  padding: 13px 22px;
  margin-right: 50px;
  text-transform: uppercase;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  display: block;
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all .3s ease-in-out 0s;
}
.main-button:hover {
  background-color: rgb(36, 34, 34);
  color: white;
  transition: all .3s ease 0s;
  transform: translateY(-3px);
}
.project-button{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.project-button > .main-button{
  margin-right: 0 !important;
}
/* Media Query */
@media (max-width: 768px) {
  .main-button {
    font-size: 15px;
    padding: 12px 18px;
    margin-right: 0px;
  }
}
@media (max-width: 320px) {
  .main-button {
    font-size: 12px;
  }
}

.skills-main-div {
  display: flex;
}
.skills-text-div {
  margin-left: 50px;
}

.skills-main-div > * {
  flex: 1 1;
  margin-bottom: 30px;
}

.skills-text{
  line-height: 30px;

}
.m-t-40{
  margin-top: 40px;
}
.skills-text-subtitle {

  line-height: 30px;
}
.skills-image-div > img {
  max-width: 100%;
  height: auto;
}

.skills-heading {
  font-size: 56px;
  font-weight: 400;
}

/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .skills-heading {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
  }
  .skills-main-div {
    flex-direction: column
  }
  .skills-text-div {
    margin-left: 0px;
    margin: 20px;
  }
  .skills-text {
    font-size: 16px;
  }
  .skills-text-subtitle {
    font-size: 16px;
    text-align: center;
    line-height: 30px;
  }
  .skills-image-div {
    /* display: none; */
    order: 2;
  }
}

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}

.software-skills-main-div{
  margin-top:auto;
}

.software-skill-inline > i {
  color: #868e96;
}

.software-skill-inline > i:hover {
  color: #000;
}
.software-skill-inline > p {
  color: #868e96;
  font-size: 10px;
}


.experience-cards-div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 1rem 1rem;
    gap: 1rem 1rem;
}

.experience-heading {
    font-size: 56px;
    font-weight: 400;
}

.experience-container {
    display: flex;
    width: 90%;
    padding: 20px 10px;
    margin: 0px auto;
    margin-top: 2rem;
}
 
.experience-container>* {
    flex: 1 1;
}

/* Media Query */

@media (min-width: 1380px) {
    .experience-heading {
        font-size: 40px;
    }
}
@media only screen and (min-width: 1200px) {

    
}



@media (max-width: 768px) {
    .experience-heading {
        font-size: 30px;
        text-align: center;
    }
}
.experience-card {
    position: relative;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    border-radius: 10px;
    border: 1px solid rgba(211, 211, 211, 0.397);
}

.experience-card:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.experience-banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 150%;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
    border-radius: 10px 10px 0px 0px;
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
    height: 11rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.experience-blurred_div {
    position: absolute;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2));
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    height: 11rem;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

.experience-div-company {
    position: absolute;
    background: transparent;
    height: 9rem;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.experience-text-company {
    text-align: center;
    padding: 1.5rem;
    margin: 0;
    color: #fff;
    font-size: 25px;
    text-align: center;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.experience-roundedimg {
    position: absolute;
    object-fit: cover;
    left: 0;
    right: 0;
    top: 7rem;
    margin-left: auto;
    margin-right: auto;
    width: 8rem;
    height: 8rem;
    background-color: white;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
    border-radius: 20%;
}

.experience-text-role {
    text-align: center;
    color: black;
    font-weight: 700;
    font-size: 25px;
    margin: 0px;
    padding-top: 1.5rem;
    line-height: normal;
}

.experience-text-date {
    text-align: center;
    color: black;
    font-size: 20px;
    margin: 0px;
    padding-top: 1rem;
    font-weight: 600;
}

.experience-text-desc {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
}

.experience-text-details {
    padding: 1.5rem;
    margin-top: 2rem;
}

/* Media Query */

@media (max-width: 1380px) {
  
    .experience-text-role {
        padding-top: 0.5rem;
        font-size: 22px;
    }
    .experience-text-company {
        font-size: 22px;
    }
    .experience-text-date {
        font-size: 18px;
    }
    .experience-roundedimg {
        width: 6.5rem;
        height: 6.5rem;
    }
}

@media (max-width: 768px) {
    .experience-text-role {
        font-size: 22px;
    }
    .experience-text-company {
        font-size: 22px;
    }
    .experience-text-date {
        font-size: 18px;
    }
    .experience-text-desc {
        font-size: 16px;
    }
}
.jobPoint{
    margin-top: 15px;
}
.web-projects {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 1rem;

}

.company-logo-container>img {
  width: 150px;
  height: 95px;
  object-fit: contain;
}




.company-logo-container {
  width: 100%;
  margin: auto;

}

.web-projects>* {
  flex: 1 1;
  margin-bottom: 30px;
}

.starup-project-image>img {
  max-width: 100%;
  height: auto;
}

.project-card-head {
  color: rgb(36, 41, 46);
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin: 0px;
}

.startup-project-text {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-content: space-between;
}

.project-card-description {
  color: rgb(88, 96, 105);
}

.company-worked-with {
  align-items: center;
  cursor: pointer;
}

@media (max-width: 768px) {
  .starup-project-image {
    display: none;
  }


  

  .company-logo {
    max-width: 300px;
  }

  .project-subtitle {
    font-size: 16px;
    text-align: center;
  }
}
.achievement-heading {
  font-size: 56px;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
  margin-bottom: 10px;
}
.achievement-subtitle {
  text-transform: uppercase;
  margin: 0px;
  margin-bottom: 50px;
}
/* Media Query */
@media (max-width: 1380px) {
  .achievement-heading {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .achievement-heading {
    font-size: 30px;
    text-align: center;
  }
  .achievement-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
}

.card-image {
    max-width: 100%;
    height: auto;
    width: 250px;
}
.card-title {
    color: #000000;
    font-size: 22px;
    line-height: 24px; 
    font-weight: 700;
    margin: 15px 0 0 0;
}
.card-subtitle {
    color: #666666;
    font-size: 17px;
    line-height: 1.5rem;
}
.certificate-card {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    padding: 1.5rem;
    border-radius: 10px;
    border: 1px solid rgba(211, 211, 211, 0.397);
}
.certificate-detail-div {
text-align: center;
}
.certificate-card:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}
.certificate-image-div {
    position: relative;
    margin: auto;
    height: 250px;
    width: 150px;
    object-fit: contain;
    overflow: hidden;
    text-align: center;
}
.achievement-cards-div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1rem 1rem;
    gap: 1rem 1rem;
}
.certificate-card-footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
.certificate-card-footer span.certificate-tag {
    background: #000;
    color: #f5f2f4;
    vertical-align: middle;
    align-items: center;
    border-radius: 4px;
    display: inline-flex;
    font-size: .95rem;
    height: 2em;
    justify-content: center;
    white-space: nowrap;
    line-height: 1.5;
    margin: 0 .5rem .5rem 0;
    padding: 0 .75em;
    cursor: pointer;
    transition: 0.20s ease-in;
}
span.certificate-tag:hover {
    background: rgb(80, 76, 76);
}
/*Blog Cards Layout*/
.square {
  width: 440px;
  height: 430px;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 20px 50px #d9dbdf;
  transition: all 0.3s ease;
  margin-top: 30px;
}

.square:hover {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
}

.blog-header-text {
  font-size: 56px;
  font-weight: 400;
}

.blog-subtitle {
  text-transform: uppercase;
}
.blog-main-div > * {
  flex: 1 1;
  margin-bottom: 30px;
}
.blog-main-div {
  display: flex;
}
.blog-image-div > img {
  max-width: 100%;
  height: auto;
}
.blog-text-div{
  display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 32px;
    gap: 32px;
}

/* Media Query */
@media (max-width: 1380px) {
  .blog-header-text {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .blog-header-text {
    font-size: 30px;
    text-align: center;
  }
  .blog-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
}

@media screen and (max-width: 480px) and (min-width:300px){
  .blog-text-div{
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
.blog-container {
  border-radius: 10px;
  color: #586069;
  background-color: #fff;
  box-shadow: 0 0 36px rgba(0, 0, 0, 0.1);
  /* padding: 2rem; */
  cursor: pointer;
  height: 200px;
}

.blog-title {
  color: #262626;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 14px;
  margin-top: 32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.small {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5rem;
  color: #666666;
  padding: 0px 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0px;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #000;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.blog-card {
  display: block;
  position: relative;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  height: 100%;
}

.blog-card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #000;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.4s ease-out;
}

.blog-card:hover::before {
  transform: scale(30);
}

.blog-card:hover > .small {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.blog-card:hover > h3 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}

@media screen and (max-width: 480px) and (min-width: 300px), (min-width: 925px) and (max-width: 1024px), (min-width: 1405px) and (max-width: 1550px) {
  .blog-card:hover::before {
    transform: scale(30);
  }
}

@media screen and (min-width: 481px) and (max-width: 675px), (min-width: 1025px) and (max-width: 1404px), (min-width: 1551px) {
  .blog-card:hover::before {
    transform: scale(40);
  }
  .blog-card::before {
    transition: transform 0.45s ease-out;
  }
}

@media screen and (min-width: 675px) and (max-width: 860px) {
  .blog-card:hover::before {
    transform: scale(50);
  }
  .blog-card::before {
    transition: transform 0.5s ease-out;
  }
}

@media screen and (min-width: 860px) and (max-width: 924px) {
  .blog-card:hover::before {
    transform: scale(60);
  }
  .blog-card::before {
    transition: transform 0.5s ease-out;
  }
}

@media screen and (max-width: 480px) and (min-width: 300px) {
  .blog-title {
    font-size: 18px;
  }
}

#topButton {
  visibility:hidden;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #000;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 15px;
  font-size: 25px;
}

#topButton:hover {
  background-color: #000;
  transition: all ease-in-out 0.2s;
}

.contact-div-main {
  display: flex;
}

.contact-div-main > * {
  flex: 1 1;
}

.contact-title {
  font-size: 65px;
  font-weight: 400;
}

.contact-detail,
.contact-detail-email {
  margin-top: 20px;
  font-size: 40px;
  font-weight: 400;
  color: #868e96;
  text-decoration: none;
}

.contact-detail:hover,
.contact-detail-email:hover {
  color: black;
  text-shadow: 2px 1px 2px #b5b5b5;
  transition: all 0.3s;
}

.contact-subtitle {
  font-weight: 400;
}

.contact-text-div {
  margin-top: 1.5rem;
}

.contact-margin-top {
  margin-top: 4rem;
}

/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {
  .contact-title {
    font-size: 56px;
    font-weight: 400;
    text-align: center;
  }

  .contact-title {
    font-size: 40px;
    text-align: center;
  }

  .contact-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .contact-text-div {
    text-align: center;
    margin-top: 1.5rem;
  }

  .contact-detail,
  .contact-detail-email {
    font-size: 20px;
  }
  .contact-div-main {
    display: flex;
    flex-direction: column;
  }
}

